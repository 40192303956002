
import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import ContactUs from '../components/forms/ContactUs';

class WithPurposeRealty extends React.Component {

    render() {
                
        return (
        <>

            <div className="main-content">

                <div className="header bg-secondary pt-5 pb-7" >
                    <Container className=" pt-2 pb-3">
                        <div className="header-body">
                            <Row className="align-items-center">

                                <Col lg="6">
                                    <div className="pr-5 ">

                                        <h1 className="display-2  font-weight-bold mb-0" style={{lineHeight: '50px'}}>With Purpose Realty</h1>
                                        <h2 className="display-4  font-weight-light"> Home Buying Made Easy </h2>

                                        <p className=" mt-4">With Purpose Realty is a streamlined application making it easy for prospective home owners apply to buy a home and talk with respected Realtors.</p>

                                        <div className="mt-5">
                                            <Link to="/contact" className="btn my-2 btn-success" > Send Us A Message </Link>
                                        </div>
                                    </div>
                                </Col>

                                <Col className="d-none d-lg-block" lg="6" style={{textAlign: 'center'}}>
                                    <img alt="..." className="rounded shadow-lg img-fluid ml-auto mr-auto mb-3" src="https://res.cloudinary.com/architeck/image/upload/v1582238459/Projects/WithPurposeRealty/WithPurposeMain_xrlnfr.jpg" />

                                </Col>
                            </Row>
                        </div>
                    </Container>

                </div>

                <section className="py-6 bg-white">
                    <Container>
                        <Row>
                            <Col lg={6}>
                                <h3 className="display-2"> Helping People Find The Perfect Home </h3>

                                <p className=""></p>
                                <p>The task was simple, redefine how an industry works. Just an every day task right? Over a period of three months With Purpose Realty's on onboarding system was complete and process of streamlining their lead generation and onboarding process was ready to go.</p>

                                <hr />

                                <p>Their newly created software connects to their current CRM (Liondesk) stores data via Firebase, and displays the data collected from their prospective buyers in a simple and easy to understand way.</p>

                                <hr />

                                <p>After the project was finished we migrated their existing Wordpress site and connected the home buying process to a subdomain keeping everything their company offers in one place.</p>

                                <hr />

                                <p className="">Infrastructure After Project Finish:</p>
                                <ul>
                                    <li>Migrated WordPress Site</li>
                                    <li>Sub Domain With Newly Built Software</li>
                                    <li>Visual Backend Via Firebase</li>
                                    <li>Integrated CRM Via Liondesk</li>
                                </ul>

                            </Col>
                            <Col lg={6}>

                                <img className="shadow-lg rounded mt-4 w-100" src="https://res.cloudinary.com/architeck/image/upload/v1582238459/Projects/WithPurposeRealty/WithPurposeMain_xrlnfr.jpg" alt="..." />
                              

                                <Row>
                                    <Col lg={6}>
                                        <img className="shadow-lg rounded mt-4 w-100" src="https://res.cloudinary.com/architeck/image/upload/v1582238221/Projects/WithPurposeRealty/Screen_Shot_2020-02-18_at_8.03.53_PM_iok8ec.png" alt="..." />
                                    </Col>
                                    <Col lg={6}>
                                        <img className="shadow-lg rounded mt-4 w-100" src="https://res.cloudinary.com/architeck/image/upload/v1582239520/Projects/WithPurposeRealty/Screen_Shot_2020-02-20_at_5.58.33_PM_rmd4pu.png" alt="..." />
                                    </Col>
                                </Row>

                                <img className="shadow-lg rounded mt-4 w-100" src="https://res.cloudinary.com/architeck/image/upload/v1582238221/Projects/WithPurposeRealty/Screen_Shot_2020-02-18_at_8.01.58_PM_kfppm6.png" alt="..." />
                    
                            </Col>
                           
                        </Row>
                    </Container>
                </section>

                <ContactUs />

            </div>

        </>
        );
    }
}
  
export default WithPurposeRealty;  