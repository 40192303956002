
import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import ContactUs from '../components/forms/ContactUs';

class ExronMuxic extends React.Component {

    render() {
                
        return (
        <>

            <div className="main-content">

                <div className="header bg-secondary pt-5 pb-7" >
                    <Container className=" pt-2 pb-3">
                        <div className="header-body">
                            <Row className="align-items-center">

                                <Col lg="6">
                                    <div className="pr-5 ">

                                        <h1 className="display-2  font-weight-bold mb-0" style={{lineHeight: '50px'}}>Exron Music</h1>
                                        <h2 className="display-4  font-weight-light"> A New Wave Of Music </h2>

                                        <p className=" mt-4"> Exron is an independent Music Blog run by Kylie Parham and Charlotte Vosbeck. It shows off the brands unique design style with an easy to use backend user dashboard.</p>

                                        <div className="mt-5">
                                            <Link to="/contact" className="btn my-2 btn-success" > Send Us A Message </Link>
                                        </div>
                                    </div>
                                </Col>

                                <Col className="d-none d-lg-block" lg="6" style={{textAlign: 'center'}}>
                                    <img alt="..." className="rounded shadow-lg img-fluid ml-auto mr-auto mb-3" src="https://res.cloudinary.com/architeck/image/upload/v1575381210/Projects/Exron/ExronMusic_j4bg4d.jpg" />

                                </Col>
                            </Row>
                        </div>
                    </Container>

                </div>

                <section className="py-6 bg-white">
                    <Container>
                        <Row>
                            <Col lg={6}>
                                <h3 className="display-2">Other Worldy Music At Your Fingertips</h3>

                                <p className="">This tagline was created by Exron Music and perfectly describes what Exron is all about<br />We started with the challenge of making Exron Both easy to use for the site admins and creating a unique design to fit their companies style.</p>
                                <p>For this we used WordPress as a CMS and added in a custom theme giving the site ultimate flexibility in how it looks and feels.</p>

                                <hr />

                                <p>After Exron's launch in late 2017 it has grown to over 500 unique artists with hundreds of ever changing playlists and top music picks.</p>

                                <hr />

                                <p className="">Exron Features:</p>

                                <Row>
                                    <Col lg={6}>
                                        <ul>
                                            <li>New</li>
                                            <li>Interview</li>
                                            <li>Fashion</li>
                                            <li>Opinion</li>
                                            <li>Team</li>
                                            <li>Festivals</li>
                                            <li>Tours</li>
                                        </ul>
                                    </Col>
                                    <Col lg={6}>
                                        <ul>
                                            <li>Shows</li>
                                            <li>Charts</li>
                                            <li>Playlists</li>
                                            <li>Guest Mixes</li>
                                            <li>Photos</li>
                                            <li>Videos</li>
                                        </ul>
                                    </Col>
                                </Row>
                               

                            </Col>
                            <Col lg={6}>

                                <img className="shadow-lg rounded mt-4 w-100" src="https://res.cloudinary.com/architeck/image/upload/v1576064457/Projects/Exron/Exron13_yxvkev.jpg" alt="..." />
                              

                                <Row>
                                    <Col lg={6}>
                                        <img className="shadow-lg rounded mt-4 w-100" src="https://res.cloudinary.com/architeck/image/upload/v1576064457/Projects/Exron/Exron10_goft9y.jpg" alt="..." />
                                    </Col>
                                    <Col lg={6}>
                                        <img className="shadow-lg rounded mt-4 w-100" src="https://res.cloudinary.com/architeck/image/upload/v1576064457/Projects/Exron/Exron11_sxf63r.jpg" alt="..." />
                                    </Col>
                                </Row>

                                <img className="shadow-lg rounded mt-4 w-100" src="https://res.cloudinary.com/architeck/image/upload/v1576064457/Projects/Exron/Exron12_dgc4dk.jpg" alt="..." />
                    
                            </Col>
                           
                        </Row>
                    </Container>
                </section>

                <ContactUs />

            </div>

        </>
        );
    }
}
  
export default ExronMuxic;  