import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';



//tell redux to match the following properties to each reducer
const rootReducer = combineReducers({
    
    

})
  
const store = createStore(rootReducer, applyMiddleware(thunk));;
export default store