/*
Documentation

this page holds all of our layouts and functions
that need to fire before the app runs

*/

import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Site from 'views/site/layout';


class App extends Component {

   

    render() {

      

        return (

            <>
                
                <BrowserRouter>
                    <Switch>

                        <Route path="/" render={props => <Site {...props} /> } />

                    </Switch>
                </BrowserRouter>

            </>

        )

    }

}

export default App;