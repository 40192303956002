
import React from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import ContactUs from '../components/forms/ContactUs';


class PlayBook3lt extends React.Component {

    state = {
      
    }

    render() {
                
        return (
        <>

            <div className="main-content">

                <div className="header bg-secondary pt-5 pb-7" >
                    <Container className=" pt-2 pb-3">
                        <div className="header-body">
                            <Row className="align-items-center">

                                <Col lg="6">
                                    <div className="pr-5 ">

                                        <h1 className="display-2  font-weight-bold mb-0" style={{lineHeight: '50px'}}>Sky Tech Holdings</h1>
                                        <h2 className="display-4  font-weight-light"> Creating Technology For A Better And Interplanetary Future</h2>

                                        <p className=" mt-4"> Sky Tech is the creation of Ted X speaker Michael Gustin who has brought together a team of tech specialists and ex Nasa engineers focusing on a better future.</p>

                                        <div className="mt-5">
                                            <Link to="/contact" className="btn my-2 btn-success" > Send Us A Message </Link>
                                        </div>
                                    </div>
                                </Col>

                                <Col className="d-none d-lg-block" lg="6" style={{textAlign: 'center'}}>
                                    <img alt="..." className="rounded shadow-lg img-fluid ml-auto mr-auto mb-3" src="https://res.cloudinary.com/architeck/image/upload/v1582241300/Projects/SkyTech/Screen_Shot_2020-02-20_at_6.21.15_PM_wo0jik.png" />

                                   
                             
                                </Col>
                            </Row>
                        </div>
                    </Container>

                 

                </div>
                

                <section className="py-6 bg-white">
                    <Container>
                        <Row>
                            <Col lg={6}>
                                <h3 className="display-2"> The Future Is Now </h3>

                                <p>Sky Tech is made up of ex Nasa engineers, government employees and rocket scientists. A dream team if there ever was one.</p>

                                <hr />

                                <p>During their early stages they came to Architeck to create a website to attract investors and give information about the products they were creating and we couldn't be happier to work with them.</p>

                                <p>An exciting project with an incredible company and unbelievable owner... Working with companies like this was the reason we got started. </p>

                                <hr />

                                <p>While starting small, their website was created in under 4 hours of development time and gave them the credibility and online presence they needed to move their company forward.</p>

                                <hr />

                                <p>When finished Sky Tech was equipped with:</p>

                                <ul>
                                    <li>1 Simple Website</li>
                                    <li>Netlify Hosting</li>
                                    <li>GSuite Custom Email Addresses</li>
                                </ul>

                            </Col>

                            <Col lg={6}>

                                <img className="shadow-lg rounded mt-4 w-100" src="https://res.cloudinary.com/architeck/image/upload/v1582241176/Projects/SkyTech/SkytechLarge_baoerg.jpg" alt="..." />

                                <img className="shadow-lg rounded mt-4 w-100" src="https://res.cloudinary.com/architeck/image/upload/v1582241563/Projects/SkyTech/Screen_Shot_2020-02-20_at_6.32.35_PM_uurjug.png" alt="..." />

                                <img className="shadow-lg rounded mt-4 w-100" src="https://res.cloudinary.com/architeck/image/upload/v1582241461/Projects/SkyTech/Screen_Shot_2020-02-20_at_6.30.49_PM_enkeen.png" alt="..." />

                                </Col>

                           
                        </Row>
                    </Container>
                </section>

              
                <ContactUs />

            </div>

        </>
        );
    }
}


const mapStateToProps = state => {


    return {
       
      
    };
};

  
export default connect(mapStateToProps )(PlayBook3lt);  