
import React from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import ContactUs from '../components/forms/ContactUs';


class PlayBook3lt extends React.Component {

    state = {
      
    }

    render() {
                
        return (
        <>

            <div className="main-content">

                <div className="header bg-secondary pt-5 pb-7" >
                    <Container className=" pt-2 pb-3">
                        <div className="header-body">
                            <Row className="align-items-center">

                                <Col lg="6">
                                    <div className="pr-5 ">

                                        <h1 className="display-2  font-weight-bold mb-0" style={{lineHeight: '50px'}}>3LT Playbook</h1>
                                        <h2 className="display-4  font-weight-light"> Over  <b className="text-underline text-success font-weight-bold"> $100,000</b> In Revenue On Launch </h2>

                                        <p className=" mt-4">A playbook built by trader for traders giving them the laws they need to succeed in the market. </p>

                                        <div className="mt-5">
                                            <Link to="/contact" className="btn my-2 btn-success" > Send Us A Message </Link>
                                        </div>
                                    </div>
                                </Col>

                                <Col className="d-none d-lg-block" lg="6" style={{textAlign: 'center'}}>
                                    <img alt="..." className="rounded shadow-lg img-fluid ml-auto mr-auto mb-3" src="https://res.cloudinary.com/architeck/image/upload/v1575380150/Projects/3lt/3ltplaybook_r1udpc.jpg" />

                                   
                             
                                </Col>
                            </Row>
                        </div>
                    </Container>

                 

                </div>
                

                <section className="py-6 bg-white">
                    <Container>
                        <Row>
                            <Col lg={6}>
                                <h3 className="display-2"> Built With <br />Purpose </h3>

                                <p className="">The <b>3LT Playbook</b> was created and designed for <b>Sang Lucci</b> to both inform visitors about the three laws of trading and convert leads into their membership platform "The Steam Room"</p>
                                <p className="">During its first week of launch Sang Lucci brought in over <b>$100,000 in revenue</b> from the leads it converted.</p>

                                <p className="">The playbook consisted of 6 pages:</p>
                                <ul>
                                    <li>Landing / Opt In</li>
                                    <li>Intro</li>
                                    <li>Law 1</li>
                                    <li>Law 2</li>
                                    <li>Law 3</li>
                                    <li>Sales Page</li>
                                </ul>

                               <hr />

                               <p>The site was hooked up to <b>Google Analytics</b> as well as <b>Intercom</b> and <b>Crazy Egg</b> heat map tracking.</p>

                               <p>All opt ins are instantly pushed along to their Active Campaign account to be sorted into lists for email campaigns.</p>



                            </Col>
                            <Col lg={6}>
                                {/* <img className="w-100" src="https://res.cloudinary.com/architeck/image/upload/v1575385368/Projects/3lt/3lt_mif0hx.jpg" alt="..." /> */}
                                <img className="mt--4 w-100" src="https://res.cloudinary.com/architeck/image/upload/v1575385682/Projects/3lt/3lt_g2dbya.jpg" alt="..." />
                    
                            </Col>

                           
                        </Row>
                    </Container>
                </section>

              
                <ContactUs />

            </div>

        </>
        );
    }
}


const mapStateToProps = state => {


    return {
       
      
    };
};

  
export default connect(mapStateToProps )(PlayBook3lt);  