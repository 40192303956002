
import React from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Badge, Col, Container, Row } from "reactstrap";
import ContactUs from './components/forms/ContactUs';


class Home extends React.Component {

    state = {
   
    }

    render() {
        
        return (
        <>

            <div className="main-content">

                <div className="header bg-secondary pt-0 pt-lg-5  pb-0 pb-lg-7">
                    <Container className=" pt-5 pb-7">
                        <div className="header-body">
                            <Row className="align-items-center">

                                <Col lg="6">
                                    <div className="pr-5">

                                        <h1 className="display-2  font-weight-bold mb-0" style={{lineHeight: '50px'}}>Built In Advance... </h1>
                                        <h2 className="display-4  font-weight-light"> Tailored To Fit <b className="text-underline">Your Unique Situation</b>. </h2>

                                        <p className=" mt-4">Just because something can be implemented quick and cheap doesn't mean it was built quick and cheap. Below you'll see our ever growing list of ready made solutions that come custom tailed to you.</p>

                                        <div className="mt-5">
                                            <Link to="/contact" className="btn my-2 btn-success btn-success-pulse" > Send Us A Message </Link>
                                        </div>
                                    </div>
                                </Col>

                                <Col className="d-none d-lg-block" lg="6" style={{textAlign: 'center'}}>
                                    <img alt="..." className="img-fluid" src={require("assets/img/undraw/svg14.svg")} />
                                </Col>
                            </Row>
                        </div>
                    </Container>

                </div>

                <section className="section py-7 bg-white">
                    <Container>
                        <Row>
                            <Col lg={4}>
                                <img className="w-100" src={require('assets/img/brand/EdStulak.png')} alt="" />
                                <div className="text-center">
                                <Badge  className="mt-4 p-2 px-4 bg-gradient-info text-white mb-2" ill>Live In Under 7 Days</Badge>

                                </div>
                            </Col>

                            <Col lg={8}>
                                <h2 className="display-2  font-weight-bold mb-0 mt-6 mt-lg-0" style={{lineHeight: '50px'}}>Real Estate <span className="text-success d-inline font-weight-bold">$997</span></h2>
                                <h2 className="display-4  font-weight-light">In Partnership With <b className="text-underline font-weight-bol">Ed Stulak The Millenial Realtor</b></h2>


                                <hr />
                                <p>Architeck has partner with Ed Stulak to bring you the best out of the box solution for Real Estate websites on the market that is owned 100% by you!</p>
                                <p>That right, we set up your site and give you 3 hours of custom development work included in the price and then we turn over the code.</p>
                                

                                <p>To learn more <a href="https://edstulak.com" target="_blank" rel="noopener noreferrer">click here to check out his website</a> or reach out to us for more information on how it works.</p>

                            </Col>
                        </Row>
                    </Container>
                </section>

            

                <ContactUs />

            </div>

           

        </>
        );
    }
}


const mapStateToProps = state => {


    return {
       
      
    };
};

  
export default connect(mapStateToProps )(Home);  