
import React from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Badge, Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import ContactUs from './components/forms/ContactUs';
import TableComparison from './components/tables/TableComparison';



class Development extends React.Component {

    render() {
        
        return (
        <>

            <div className="main-content">

            <div className="header bg-secondary pt-0 pt-lg-5  pb-0 pb-lg-7">
                    <Container className=" pt-5 pb-7">
                        <div className="header-body">
                            <Row className="align-items-center">

                                <Col lg="6">
                                    <div className="pr-5">

                                        <h1 className="display-2  font-weight-bold mb-0" style={{lineHeight: '50px'}}>Custom Development </h1>
                                        <h2 className="display-4  font-weight-light"> Done Right Through A <b className="text-underline">Hybrid</b> Model </h2>

                                        <p className=" mt-4">Get the best of creating something new by building on the old. <br /><b>Who doesn't like saving time and money?</b> </p>

                                        <div className="mt-5">
                                            <Link to="/contact" className="btn my-2 btn-success btn-success-pulse" > Send Us A Message </Link>
                                        </div>
                                    </div>
                                </Col>

                                <Col className="d-none d-lg-block" lg="6" style={{textAlign: 'center'}}>
                                    <img alt="..." className="img-fluid ml-auto mr-auto mb-3"  src={require("assets/img/undraw/svg10.svg")} />
                                </Col>
                            </Row>
                        </div>
                    </Container>

                   
                </div>

              

                <section className="bg-white section section-lg  py-7 position-relative">
                    <Container>

                        <Row className="justify-content-center">
                            <Col lg="12">
                                <Row>

                                    <Col lg="8">
                                        <h1 className="display-2">What Is Hybrid Development?</h1>
                                        <h2 className="display-4 font-weight-light">It's the new way of <b className="text-underline">bringing your business to the next level.</b></h2>

                                        <Badge  className="mt-4 p-2 px-4 bg-gradient-danger text-white mb-2" ill>The Problem With Modern Development</Badge>

                                        <p>Out of the box solutions can be fantastic, save you money, and get the job done, but they can also be un-flexible and box your company in.</p>
                                        <p>On the other hand, building something from scratch won't box you in but costs an incredible amount of money and often times end up reinvents the wheel.</p>
                                        <p>Think about it, car companies don't reinvent the car every time the release a new model, they just build on what they did last year.</p>

                                        <p>It's {new Date().getFullYear()} and there is so much out there to build on that fully custom solutions are often times a waste of money.</p>

                                        <Badge  className="mt-4 p-2 px-4 bg-gradient-success text-white mb-2" ill>The Fool Proof Solution</Badge>
                                        <h2 className="display-5 font-weight-bold"><i className="fas fa-arrow-right"></i> Hybrid Development</h2>

                                        <p>With hybrid development we research what's already out their and what we can used to shortcut the start of any project</p>
                                        <p>In most cases we have found about 90% of all custom features have already been built and may even be open source meaning we use it at no extra cost.</p>
                                        <p>Once we find the right foundation we then tailor it to exactly what you want. <b>Building only what's needed.</b> </p>
                                        <p>We save time, you save money, and with every project we get faster and faster.</p>

                                        <hr />

                                        <p>This is the new wave of development and we are at it's forefront. <br /><b>The question is, ARE YOU?</b></p>



                                    </Col>

                                    <Col lg="4">

                                        <Card className=" shadow border-0">

                                            <CardBody className="py-5">
                                                <div className="icon icon-shape bg-gradient-info text-white rounded-circle mb-4">
                                                <i className="fas fa-laptop"></i>
                                                </div>

                                                <h4 className="h3 text-info text-uppercase">
                                                    Hybrid Websites
                                                </h4>

                                                <p className="description mt-3">There are an incredible number of design kit options out there that save developers countless hours yet still allow you get the exact look you want. </p>

                                                <div>
                                                    <Badge color="info" pill> Custom </Badge> 
                                                    <Badge color="info" pill> Fully Changeable  </Badge> 
                                                    <Badge color="info" pill> Beautiful </Badge> 
                                                </div>
                                            </CardBody>

                                        </Card>

                                        <Card className=" shadow border-0">
                                            <CardBody className="py-5">

                                                <div className="icon icon-shape bg-gradient-warning text-white rounded-circle mb-4">
                                                <i className="fas fa-rocket"></i>
                                                </div>

                                                <h4 className="h3 text-warning text-uppercase">
                                                    Hybrid Software
                                                </h4>

                                                <p className="description mt-3">
                                                    No matter what software you are building there is a good chance that some of the functionality for it has been written before. After all, think about how many times a simple dashboard login has been coded?
                                                 </p>

                                                 <div>
                                                    <Badge color="success" pill>Frameworks</Badge> 
                                                    <Badge color="success" pill>Open Source</Badge> 

                                                </div>

                                            </CardBody>
                                        </Card>
                               
                                    </Col>

                                   
                                </Row>
                            </Col>
                        </Row>
                    </Container>

                    <div className="separator separator-bottom separator-skew zindex-100">
                        <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0" >
                        <polygon className="fill-default" style={{fill: '#f8f9fe'}} points="2560 0 2560 100 0 100" />
                        </svg>
                    </div>

                </section>

                {/* ad video here */}

                <section className="section py-9 pb-6 position-relative">
                    <Container>

                  


                        <Card>
                            <CardHeader style={{borderBottom: 'none'}}>
                                

                                <h1 className="display-2 mb-0">Let's Compare</h1>
                                <p>Why the hybrid model let's us help you create things that matter.</p>
                              
                            </CardHeader>
                            <CardBody>
                                <TableComparison />
                            </CardBody>
                        </Card>
                    </Container>

                   

                </section>

                <section className="bg-white section section-lg  py-7 bg-white text-center">
                    <Container>
                        <h1 className="display-2">Don't Play With <b className="text-underline">Your Money</b></h1>
                        <h2 className="display-4 font-weight-light">We are the pioneers of hybrid development and help save clients time and money every day.</h2>
                    </Container>
                </section>
             

                <ContactUs />

                
            </div>

           

        </>
        );
    }
}


const mapStateToProps = state => {


    return {
       
      
    };
};

  
export default connect(mapStateToProps )(Development);  