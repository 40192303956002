/*
Documentation

this file renders the site layout

*/

import React from "react";
import { Route, Switch } from "react-router-dom";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import routes from "./routes";

class Site extends React.Component {

	state = {
		pathname: null
	}

	componentDidUpdate(e) {
		if (e.history.pathname !== e.location.pathname) {
			document.documentElement.scrollTop = 0;
			document.scrollingElement.scrollTop = 0;

			//incase ref is emtpy
			if(this.refs.mainContent) {
				this.refs.mainContent.scrollTop = 0;
			}
		}
	  }

	componentWillReceiveProps = () => {
		if(this.state.pathname !== window.location.pathname) {
			this.setState({pathname: window.location.pathname})
		}
	}

	componentDidMount = () => this.setState({pathname: window.location.pathname}) 
		
	//get routes for this layout
	getRoutes = routes => routes.map((prop, key) => {

        //recursive to get the dropdown routes
        if (prop.collapse) {
            return this.getRoutes(prop.views);
        }

        return (
            <Route  exact path={prop.path} component={prop.component} key={key} />
        )
    })

	render() {

		return (

			<div className="main-content" ref="mainContent">
				<Navbar pathname={this.state.pathname} />
				<Switch>{this.getRoutes(routes)}</Switch>
				<Footer />
			</div>

		);
	}
}

export default Site;
