

let keys = {
    COMPANY: 'Architeck',
    LOGO: 'https://res.cloudinary.com/architeck/image/upload/v1575321112/Branding/ArchiteckLogoCut1280_z4ahtp.png',
    PROD_HOSTNAME: 'architeck.io',
    EMAIL_SYSTEM: 'john@architeck.io', //email address to send all system emails from
    EMAIL_ADMIN: 'john@architeck.io', //email address to receive all system emails from
};
 
const HOSTNAME = window.location.hostname

//using production keys
if(HOSTNAME === keys.PROD_HOSTNAME) {

    keys = {

        ...keys, 
        
        CLIENT_URL: 'https://architeck.io',
        API_URL: 'https://archk-server-prod.herokuapp.com',
        STRIPE_PUBLISHABLE_KEY: 'pk_test_K7UgPTtGXNiBdgnuRXi1a8GA00qbvMm2Uc',
        SYSTEM_API_KEY: 'architeck-8372525114',
        PINNED_ARTICLE_ID: '5e06337bd914f700173c11fa',

    }

//using staging keys
} else if(HOSTNAME !== 'localhost') { 

    keys = {

        ...keys, 

        CLIENT_URL: 'https://architeck.io',
        API_URL: 'https://archk-server-staging.herokuapp.com',
        STRIPE_PUBLISHABLE_KEY: 'pk_test_K7UgPTtGXNiBdgnuRXi1a8GA00qbvMm2Uc',
        SYSTEM_API_KEY: 'architeck-9471648276',
        PINNED_ARTICLE_ID: '5e0218f87ea2a40c488fe3c9',


    }

//using development keys 
} else {

    keys = {

        ...keys, 
        
        CLIENT_URL: 'http://localhost:3000',
        API_URL: 'http://localhost:5000',
        STRIPE_PUBLISHABLE_KEY: 'pk_test_K7UgPTtGXNiBdgnuRXi1a8GA00qbvMm2Uc',
        SYSTEM_API_KEY: 'architeck-9471648276',
        PINNED_ARTICLE_ID: '5e0218f87ea2a40c488fe3c9',

        
    }

}

export default keys
