
import React from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import ContactUs from './components/forms/ContactUs';


class Home extends React.Component {

    state = {

    }

    render() {

        return (
        <>

            <div className="main-content">

            <div className="header bg-secondary pt-0 pt-lg-5  pb-0 pb-lg-7">

                    <Container className=" pt-5 pb-7">
                        <div className="header-body">
                            <Row className="align-items-center">

                                <Col lg="6">
                                    <div className="pr-5">

                                        <h1 className="display-2  font-weight-bold mb-0" style={{lineHeight: '50px'}}>Tailored Information Architecture </h1>
                                        <h2 className="display-4  font-weight-light">It's Not Only A Foundation, It Determines <b className="text-underline">Growth Potential</b>.</h2>

                                        <p className=" mt-4">You don't use the foundation of a house to build a shed, and you don't build a house with the materials you use to engineer a car.</p>
                                        <p>That's exactly why you need the correct architecture laid down for where you are now in your business and online applications. One that matches you but has the flexibility to grow at scale.</p>

                                        <div className="mt-5">
                                            <Link to="/contact" className="btn my-2 btn-success btn-success-pulse" > Send Us A Message </Link>   
                                        </div>
                                    </div>
                                </Col>

                                <Col className="d-none d-lg-block" lg="6" style={{textAlign: 'center'}}>
                                    <img alt="..." className="img-fluid" src={require("assets/img/undraw/svg22.svg")} />
                                </Col>

                            </Row>
                        </div>
                    </Container>


                    <div className="separator separator-bottom separator-skew zindex-100">
                        <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0" >
                        <polygon className="fill-default" style={{fill: 'white'}} points="2560 0 2560 100 0 100" />
                        </svg>
                    </div>

                </div>

                <div className="section py-7 bg-white position-relative">
                    <Container className="text-center">

                        <h2 className="display-2  font-weight-bold mb-0 text-left mb-0" >How It Works</h2>
                        <p className="text-left">Setting up and fixing systems architecture can be a long process but we've boiled it down into just a few phases.</p>
                        <hr />


                        <Row className="mb-5">
                            <Col md={4} className="ml-auto mr-auto text-left">
                                <h2 className="display-4  font-weight-bold mb-0" >1) Current Situation</h2>
                                <p>We start by taking a look at where you are now, what systems you have set up, and where you want to go in the future.</p>
                                <img alt="..." className="w-75" src={require("assets/img/undraw/svg2.svg")} />

                            </Col>
                            <Col md={4} className="ml-auto mr-auto text-left text-lg-center mt-5 mt-lg-0">
                            <h2 className="display-4 font-weight-bold mb-0" >2) Database Structure</h2>
                                <p>We then move into the next area which is deciding on the correct database for you based on what we discover in step 1.</p>
                                <img alt="..." className="w-75" src={require("assets/img/undraw/svg24.svg")} />
                            </Col>
                            <Col md={4} className="ml-auto mr-auto text-left text-lg-right mt-5 mt-lg-0">
                                <h2 className="display-4 font-weight-bold mb-0" >3) Security</h2>
                                <p>Once we know where you are and what will hold you data we work on a plan to keep this data safe and secure.</p>
                                <img alt="..." className="w-75" src={require("assets/img/undraw/svg23.svg")} />

                            </Col>
                        </Row>

                        <Row className="mb-0 mt-6">
                            <Col md={4} className="ml-auto mr-auto text-left text-lg-center">
                                <h2 className="display-4 font-weight-bold mb-0 mt-5 mt-lg-0 " >4) Strategy</h2>
                                <p>Also known as how steps 1-3 be should implemented. Thankfully with the plan we create for you, implementation is a piece of cake.</p>
                                <img alt="..." className="w-75" src={require("assets/img/undraw/svg25.svg")} />

                            </Col>
                        </Row>

                        <Row className="mb-5">
                            <Col md={4} className="ml-auto mr-auto text-left mt-5 mt-lg-0">
                                <h2 className="display-4 font-weight-bold mb-0" >5) Migrations</h2>
                                <p>Should your database need to be changed or altered from step 1 we create the scripts for migrating all your current data.</p>
                                <img alt="..." className="w-75" src={require("assets/img/undraw/svg26.svg")} />

                            </Col>
                            <Col md={4} className="ml-auto mr-auto">
                           
                            </Col>
                            <Col md={4} className="ml-auto mr-auto mt-5 mt-lg-0 text-left text-lg-right">
                                <h2 className="display-4 font-weight-bold mb-0" >6) Implementation</h2>
                                <p>Who should execute on the strategy created in step 4? Do your developers do it? Should parts be outsourced? Etc.</p>
                                <img alt="..." className="w-75" src={require("assets/img/undraw/svg27.svg")} />

                            </Col>
                        </Row>
                        <Row className="mb-5">

                            <Col md={4} className="ml-auto mr-auto text-left text-lg-center">
                                <h2 className="display-4 font-weight-bold mb-0 mt-5 mt-lg-0" >7) Monitoring</h2>
                                <p>Once your new information architecture has been laid it out doesn't mean you can leave it alone forever. We help you monitor the process of growth at scale.</p>
                                <img alt="..." className="w-75" src={require("assets/img/undraw/svg28.svg")} />

                            </Col>
                        </Row>

                    </Container>


                    <div className="separator separator-bottom separator-skew zindex-100">
                        <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0" >
                        <polygon className="fill-default" style={{fill: '#f8f9fe'}} points="2560 0 2560 100 0 100" />
                        </svg>
                    </div>

                </div>

                <ContactUs />

            </div>

        </>
        );
    }
}


const mapStateToProps = state => {


    return {
       
      
    };
};

  
export default connect(mapStateToProps )(Home);  