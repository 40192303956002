
import React from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Badge, Card, CardBody, Col, Container, Row, ListGroup, ListGroupItem } from "reactstrap";
import ContactUs from './components/forms/ContactUs';
import Description from './components/text/Description';
import { architeckCall } from 'database';
import HeroHalf from 'components/markup/heros/Half';
import moment from 'moment';
import keys from 'keys'

class Home extends React.Component {

    state = {
        article: { post_author: {}}
    }

    componentDidMount = async () => {

        const article = await architeckCall({
            method: 'get',
            url: '/api/v1/archk_articles/list/?find_by=_id__' + keys.PINNED_ARTICLE_ID,
        })


        if(article.success) {
            this.setState({article: article.data[0], loaded: true})
        } 

    }

    render() {
                
        return (
        <>

            <div className="main-content">

                <div className="header bg-secondary pt-1 pb-7">
                    <Container className=" pt-2 pb-3">
                        <div className="header-body">
                            <Row className="align-items-center">

                                <Col xl="6" lg={7}>
                                    <div className="pr-5 ">

                                        <h1 className="display-2  font-weight-bold mb-0" style={{lineHeight: '50px'}}>Redefining Systems Development</h1>
                                        <h2 className="display-4  font-weight-light"> Creating <b className="text-underline">Solutions</b> That Last </h2>

                                        <p className=" mt-4">Every business needs a system and the better the system, the better the company. There are no exceptions and that's where we come in. </p>

                                        {/* <span style={{height: 50, width: 50}} className="btn-success-pulse"></span> */}
                                        <div className="mt-5">
                                            <Link to="/contact" className="btn my-2 btn-success  btn-success-pulse" > Send Us A Message </Link>
                                        </div>
                                    </div>
                                </Col>

                                <Col xl="6" lg={5} className="d-none d-lg-block"  style={{textAlign: 'center'}}>
                                    <img alt="..." className="img-fluid ml-auto mr-auto mb-3" style={{maxWidth: '60%'}} src={require("assets/img/undraw/svg17.svg")} />

                                    <Row>
                                        <Col sm={6}>
                                        <img alt="..." className="img-fluid" src={require("assets/img/undraw/svg6.svg")} />
                                        </Col>
                                        <Col sm={6}>
                                        <img alt="..." className="img-fluid" src={require("assets/img/undraw/svg7.svg")} />
                                        </Col>
                                    </Row>
                             
                                </Col>
                            </Row>
                        </div>
                    </Container>

                    <div className="separator separator-bottom separator-skew zindex-100">
                        <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0" >
                        <polygon className="fill-default" points="2560 0 2560 100 0 100" />
                        </svg>
                    </div>

                </div>

                <Description />

                <section className="section section-lg pt-lg-0 mt--7">
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg="12">
                                <Row>

                                    <Col lg="4">
                                        <Card className=" shadow border-0">

                                            <CardBody className="py-5">
                                                <div className="animate-360-pause icon icon-shape bg-gradient-info text-white rounded-circle mb-4">
                                                <i className="fas fa-laptop"></i>
                                                </div>

                                                <h4 className="h3 text-info text-uppercase">
                                                    Websites
                                                </h4>

                                                <p className="description mt-3">
                                                Quite frankly, for many people a website won't bring you more customers. <b> BUT not having one will kill your credibility </b>and turn off many customers. Imaging if Amazon was just name and didn't have a website...
                                                </p>

                                                <div>
                                                    <Badge color="info" pill> Beautiful </Badge> 
                                                    <Badge color="info" pill> Responsive </Badge> 
                                                    <Badge color="info" pill> Manageable </Badge> 
                                                </div>
                                            </CardBody>

                                        </Card>
                                    </Col>

                                    <Col lg="4">
                                        <Card className=" shadow border-0">
                                            <CardBody className="py-5">

                                                <div className="animate-360-pause icon icon-shape bg-gradient-success text-white rounded-circle mb-4">
                                                <i className="fas fa-code"></i>
                                                </div>

                                                <h4 className="h3 text-success text-uppercase">
                                                    Data Flow
                                                </h4>

                                                <p className="description mt-3">If you're still manually putting in data to your CRM's, websites, CMS's and email platforms your doing something wrong. Put your data in once and watch it sync to everything. Business Automation made simple.</p>

                                                <div>
                                                    <Badge color="success" pill> API Integration </Badge> 
                                                    <Badge color="success" pill> Webhooks </Badge> 
                                                </div>

                                            </CardBody>
                                        </Card>
                                    </Col>

                                    <Col lg="4">
                                        <Card className=" shadow border-0">
                                            <CardBody className="py-5">

                                                <div className="animate-360-pause icon icon-shape bg-gradient-warning text-white rounded-circle mb-4">
                                                <i className="fas fa-rocket"></i>
                                                </div>

                                                <h4 className="h3 text-warning text-uppercase">
                                                    Real Time Dashboards
                                                </h4>

                                                <p className="description mt-3">
                                                    Seeing your data in live time makes a difference. Most customers leave a websites and abandon a sales sooner than a squirrel runs up a tree. See it happen and adjust accordingly. No hassle. No waiting. 
                                                 </p>

                                                 <div>
                                                    <Badge color="success" pill>Live Support</Badge> 
                                                    <Badge color="success" pill>Payment Tracking</Badge> 

                                                </div>

                                            </CardBody>
                                        </Card>
                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="py-6">
                    <Container>

                    <h1 className="display-1">Architeck Services</h1>
                    <hr />

                        <Row className="row-grid align-items-center">

                            <Col className="order-md-2" md="6">
                                <img alt="..." className="img-fluid" src={require("assets/img/undraw/svg4.svg")} />
                            </Col>

                            <Col className="order-md-1" md="6">
                                <div className="pr-md-5">
                                    <h1>CUSTOM DEVELOPMENT SOLUTIONS </h1>
                                    <p>We use the hybrid model to make anything and everything you can think of come to life in record time. See the advantages of a <Link to="/development">Hybrid System here.</Link></p>

                                    <p><Link to="/development" className="font-weight-bold">Learn More</Link></p>
                                    <ul className="list-unstyled mt-5">
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <Badge className="badge-circle mr-3" color="success" >
                                                    <i className="fas fa-mobile"></i>
                                                    </Badge>
                                                </div>
                                                <div>
                                                    <h4 className="mb-0">
                                                    Mobile Friendly
                                                    </h4>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <Badge className="badge-circle mr-3" color="success" >
                                                        <i className="fas fa-laptop"></i>
                                                    </Badge>
                                                </div>
                                                <div>
                                                    <h4 className="mb-0">Fast Load Times</h4>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <Badge className="badge-circle mr-3" color="success" >
                                                        <i className="fas fa-headset"></i>
                                                    </Badge>
                                                </div>
                                                <div>
                                                    <h4 className="mb-0">Established Code Base</h4>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

              <Container>  
                  <hr className="my--1" />
               </Container>

                <section className="py-6">
                    <Container>
                        <Row className="row-grid align-items-center">
                            <Col md="6">
                            <img alt="..." className="img-fluid" src={require("assets/img/undraw/svg6.svg")} />

                            </Col>
                            <Col md="6">
                                <div className="pr-md-5">
                                    <h1>Architecture Consulting  </h1>
                                    <p>Your business is only as strong as your foundation. If you're small don't pay for a billion dollar infrastructure and if your big don't waste time with messing around. Get the system architecture that fits your needs and is tailored specifically to you. </p>

                                    <Link to="/architecture" className="btn btn-success btn-success-pulse">Learn More</Link>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                <Container>  
                    <hr className="my--1" />
                </Container>

                <section className="py-6  position-relative">
                    <Container>
                        <Row className="row-grid align-items-center">

                            <Col className="order-md-2" md="6">
                                <img alt="..." className="img-fluid" src={require("assets/img/undraw/svg20.svg")} />
                            </Col>

                            <Col className="order-md-1" md="6">
                                <div className="pr-md-5">
                                    <h1>Ready Made Solutions </h1>
                                    <p>In many cases, ready made solutions are the way to go. If you need a simple and instant solution check out our newly launched marketplace of prebuilt and deployable websites and software </p>
                                    <Link to="/solutions" className="btn btn-info btn-info-pulse">Check It Out</Link>
                                </div>
                            </Col>

                        </Row>
                    </Container>

                </section>

               {this.state.article && this.state.article._id && (
                <section className=" position-relative">
                    <HeroHalf filter={.8} padding={200} theme="dark" img={this.state.article.post_image}>
                        <Container>
    
                            <Row>
                                <Col lg={7} mg={7}>
    
                                    <h1 className="display-1 text-white">{this.state.article.post_name}</h1>
    
                                    <p className="text-muted mb-0 text-sm mt-3">{ moment.unix(this.state.article.created_at).format("MMM D, YYYY")}</p>
    
                                    <p className="text-white">{this.state.article.post_excerpt}</p>
    
                                    <div className="mb-3">
                                        <span style={{display: 'inline-block'}} className="mr-3">

                                            {console.log(this.state.article)}

                                            {/* <img style={{width: 50, borderRadius: 50, marginTop: -20}} src={this.state.article.post_author.picture_url} alt="" /> */}

                                        {/* static image for now, only one author */}
                                            <img style={{width: 50, borderRadius: 50, marginTop: -20}} src={"https://res.cloudinary.com/architeck/image/upload/v1572820069/RealEstate/2019-11-03T22:27:49.853Z.jpg"} alt="" />
                                        </span>
                                        <span style={{display: 'inline-block', paddingTop: 10}}>
                                            <span className="text-white">
                                                Author <br /> 
                                                <b >{this.state.article.post_author.given_name} {this.state.article.post_author.family_name}</b>
                                            </span>
                                        </span>
                                    </div>
    
    
                                    <a target="_blank" rel="noopener noreferrer" href={`https://articles.architeck.io/article/${this.state.article._id}`}  className="mt-2 btn btn-neutral">Read More</a>
                                </Col>
                            </Row>
    
                          
                        </Container>
                    </HeroHalf>
                </section>
               )}
                

                <section className="py-7 pb-9  position-relative">

                    <Container>

                        <div className="text-center">
                            <h1 className="display-1">What People Say</h1>
                            <p></p>

                        </div>

                        <Card>

                            <CardBody className="p-0">
                                <ListGroup flush>
                                    <ListGroupItem className="list-group-item-action flex-column align-items-start py-4 px-4">
                                        <div className="d-flex w-100 justify-content-between">
                                            <div>
                                                <div className="d-flex w-100 align-items-center">
                                                    <img
                                                    alt="..."
                                                    className="avatar avatar-xs mr-2"
                                                    src={require('assets/img/brand/Headshot-EdStulak.jpg')}
                                                    />
                                                    <h3 className="mb-1">Ed Stulak</h3>
                                                </div>
                                            </div>
                                            <small></small>
                                        </div>
                                        <h5 className="mt-3 mb-1"> The Millenial Realtor </h5>
                                        <span className="text-sm mb-0">"Architecks's work ethic and patience throughout building my website was pleasing to say the least. When I needed to add new elements or wanted revisions on my site, their immediate responses and quick fixes were always reassuring. I will definitely be referring Architeck to friends and colleagues! A++!"</span>
                                    </ListGroupItem>
                                    <ListGroupItem className="list-group-item-action flex-column align-items-start py-4 px-4">
                                        <div className="d-flex w-100 justify-content-between">
                                            <div>
                                                <div className="d-flex w-100 align-items-center">
                                                    <img
                                                    alt="..."
                                                    className="avatar avatar-xs mr-2"
                                                    src={require('assets/img/brand/Headshot-MikeVivino.jpg')}
                                                    />
                                                    <h3 className="mb-1">Dave Vivino</h3>
                                                </div>
                                            </div>
                                            <small></small>
                                        </div>
                                        <h5 className="mt-3 mb-1"> Owner Pasco Soccer Club </h5>
                                        <span className="text-sm mb-0">"Architeck was a pleasure to work with and built a site that's not only easy to use but our parents and kids love as well. THey delivered exactly what we were looking for and provided great insights along the way"</span>
                                    </ListGroupItem>
                                    <ListGroupItem className="list-group-item-action flex-column align-items-start py-4 px-4">
                                        <div className="d-flex w-100 justify-content-between">
                                            <div>
                                                <div className="d-flex w-100 align-items-center">
                                                    <img
                                                    alt="..."
                                                    className="avatar avatar-xs mr-2"
                                                    src={require('assets/img/brand/Headshot-DanDeraney.jpg')}
                                                    />
                                                    <h3 className="mb-1">Dan Deraney ESQ</h3>
                                                </div>
                                            </div>
                                            <small></small>
                                        </div>
                                        <h5 className="mt-3 mb-1"> The Law Offices Of Daniel F Deraney </h5>
                                        <span className="text-sm mb-0">"Working with Architeck over the past few years on my website has been a great experience and one that I've learned a lot through. Through it all, John and Architeck have kept me informed on my options, allowed me to take the reigns on updating the website, and helped me learn so much more about the entire process."</span>
                                    </ListGroupItem>
                                </ListGroup>
                            </CardBody>
                        </Card>

                    </Container>
                </section>

                <section className="bg-white section section-lg  py-7 bg-white text-center">
                    <Container>
                        <h1 className="display-2">Don't Play With <b className="text-underline">Your Money</b></h1>
                        <h2 className="display-4 font-weight-light">We are the pioneers of hybrid development and help save clients time and money every day.</h2>
                    </Container>
                </section>

                <ContactUs />

            </div>

        </>
        );
    }
}


const mapStateToProps = state => {


    return {
       
      
    };
};

  
export default connect(mapStateToProps )(Home);  