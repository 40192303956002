import React from 'react'
import PropTypes from 'prop-types';

const Half = (props) => {

    let filter = props.filter ? props.filter : .5
    let background = props.theme === 'light' ? `rgba(255,255,255,${filter})` : `rgba(0,0,0,${filter})`
    let padding = props.padding ? `${props.padding}px 0px` : `200px 0`;

    return (

        <div 
            className="hero hero-half"
            style={{
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundImage: `url("${props.img}")`
            }} 
        >
            <div 
                style={{
                    background,
                    padding
                }}
            >
                {props.children}
            </div>
        </div>
    );

}

Half.propTypes = {
    filter: PropTypes.number,
    padding: PropTypes.number,
    theme: PropTypes.oneOf(['dark', 'light']),
    img: PropTypes.string.isRequired
};

export default Half;