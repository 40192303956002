/*
Documentation

this file renders the navbar for the site layout

*/

import keys from "keys";
import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Nav, Navbar, NavbarBrand, NavItem, NavLink, Row, UncontrolledCollapse } from "reactstrap";

class SiteNavbar extends React.Component {

    state = {
        pathname: ''
    }

    getClassName = (statePath, path) => {

        if(statePath === path) {
            return 'nav-link-inner--text text-underline'
        } else if(path === 'projects') {

           if(statePath.includes('projects')) {
            return 'nav-link-inner--text text-underline'

           }

        }

        return 'nav-link-inner--text'
        
    }
    
    openNavigation = () => {
        document.getElementById('navbar-open').classList.add('show')
    }

    closeNavigation = () => {
        document.getElementById('navbar-open').classList.remove('show')
    }

    render() {

        return ( 
        <> 


        <div style={{
            position: 'absolute', 
            top: 0,  
            left: 0, 
            right: 0, 
            height: 10, 
            background: 'linear-gradient(87deg, #11cdef 0, #1171ef 100%)',
            zIndex: 9999
        }} />


        <Navbar className="navbar-horizontal navbar-main navbar-light bg-secondary " expand="lg" id="navbar-main">

            <Container className='mt-1'>

                <NavbarBrand to="/" tag={Link}>
                    <img style={{height: 60}} className="ml-0 ml-sm-3 ml-lg-0" alt="..."  src={keys.LOGO} />
                </NavbarBrand>
                
                <button
                    className="navbar-toggler"
                    onClick={this.openNavigation}
                    type="button">
                    <i className="fas fa-bars text-dark" style={{marginTop: 13}}></i>
                </button>

                <UncontrolledCollapse className="navbar-custom-collapse" navbar toggler="#navbar-collapse" id="navbar-open">
                    <div className="navbar-collapse-header">
                        <Row>
                            <Col className="collapse-brand" xs="6">
                                <Link to="/" onClick={this.closeNavigation} >
                                    <img alt="..." src={keys.LOGO}/>
                                </Link>
                            </Col>
                            <Col className="collapse-close" xs="6"   onClick={this.closeNavigation}>
                                <span id="navbar-collapse" />
                                <a onClick={(e) => {e.preventDefault(); this.closeNavigation()}} href="/#">
                                    <i style={{color: 'black', fontSize: 20}} onClick={this.closeNavigation}  className="fas fa-times"></i>
                                </a>
                            </Col>
                        </Row>
                    </div>

                    <Nav className="align-items-lg-center ml-lg-auto" navbar>

                        <NavItem>
                            <NavLink to="/projects" tag={Link}>
                                <span onClick={this.closeNavigation}  className={this.getClassName(this.props.pathname, '/projects')}>Projects</span>
                            </NavLink>
                        </NavItem>

                        <NavItem>
                            <NavLink to="/about" tag={Link}>
                                <span onClick={this.closeNavigation}  className={this.getClassName(this.props.pathname, '/about')}>About</span>
                            </NavLink>
                        </NavItem>

                        <NavItem>
                            {/* <NavLink to="/solutions" tag={Link}>
                                <span onClick={this.closeNavigation}  className={this.getClassName(this.props.pathname, '/solutions')}>Solutions</span>
                            </NavLink> */}
                            <NavLink href="https://articles.architeck.io">
                                <span onClick={this.closeNavigation}  className={this.getClassName(this.props.pathname, '/solutions')}>Knowledge</span>
                            </NavLink>
                        </NavItem>

                        <NavItem>
                            <NavLink to="/architecture" tag={Link}>
                                <span onClick={this.closeNavigation}  className={this.getClassName(this.props.pathname, '/architecture')}>Architecture</span>
                            </NavLink>
                        </NavItem>

                        <NavItem>
                            <NavLink to="/development" tag={Link}>
                                <span onClick={this.closeNavigation}  className={this.getClassName(this.props.pathname, '/development')}>Development</span>
                            </NavLink>
                        </NavItem>
                 
                        <NavItem className="ml-lg-4">
                            <Link onClick={this.closeNavigation}  className="btn btn-neutral btn-icon btn-info" to="/contact" >
                                <span className="btn-inner--icon">
                                    <i className="fas fa-comments mr-2"></i>
                                </span>
                                <span className={this.getClassName(this.props.pathname, '/contact')}>Contact</span>
                            </Link>
                        </NavItem>
                    </Nav>

                </UncontrolledCollapse>
            </Container>
        </Navbar> 
        </>
    );
  }
}
  
export default SiteNavbar;
