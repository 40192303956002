/*
Documentation

this page holds all the routes for the site layout

*/

import About from '../pages/About';
import Contact from '../pages/Contact';
import Home from '../pages/Home';

import Architecture from '../pages/Architecture';
import Development from '../pages/Development';
import Projects from '../pages/Projects';
import Solutions from '../pages/Solutions';


import LegalTerms from '../pages/legal/Terms';

import ProjectsPlaybook3lt from '../pages/_projects/Playbook3lt';
import Goldfingr from '../pages/_projects/Goldfingr';
import ExronMusic from '../pages/_projects/ExronMusic';
import Igre from '../pages/_projects/IGRE';
import WithPurposeRealty from '../pages/_projects/WithPurposeRealty';
import SkyTech from '../pages/_projects/SkyTech';

export default [

    {
        path: "/projects/3ltplaybook",
        component: ProjectsPlaybook3lt,
        layout: "",
        renderOn: 'site'
    },
    
    {
        path: "/projects/goldfingr",
        component: Goldfingr,
        layout: "",
        renderOn: 'site'
    },
    {
        path: "/projects/exron",
        component: ExronMusic,
        layout: "",
        renderOn: 'site'
    },
    {
        path: "/projects/igre",
        component: Igre,
        layout: "",
        renderOn: 'site'
    },
    {
        path: "/projects/withpurposerealty",
        component: WithPurposeRealty,
        layout: "",
        renderOn: 'site'
    },
    {
        path: "/projects/skytech",
        component: SkyTech,
        layout: "",
        renderOn: 'site'
    },

    
    {
        path: "/architecture",
        component: Architecture,
    }, 
    
    {
        path: "/development",
        component: Development,
    }, 
    
    {
        path: "/projects",
        component: Projects,
    }, 
  
    {
        path: "/solutions",
        component: Solutions,
    },

    {
        path: "/contact",
        component: Contact,
    },

    {
        path: "/about",
        component: About,
    },

    {
        path: "/terms",
        component: LegalTerms,
    },

    //must be last
    {
        path: "",
        component: Home,
    },

]