
import React from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import ContactUs from './components/forms/ContactUs';



class Home extends React.Component {

    state = {
  
    }
    render() {

        return (
        <>

            <div className="main-content">

                <div className="header bg-secondary pt-5 pb-7">
                    <Container className=" pt-5 pb-7">
                        <div className="header-body">
                            <Row className="align-items-center">

                                <Col lg="6">
                                    <div className="pr-5">

                                        <h1 className="display-2  font-weight-bold mb-0" style={{lineHeight: '50px'}}>Who We Are </h1>
                                        <h2 className="display-4  font-weight-light"> <b className="text-underline">And Why</b> We Do What We Do </h2>

                                        <p className=" mt-4">Every business needs a system and the better the system, the better the company. There are no exceptions and that's where we come in. </p>

                                        <div className="mt-5">
                                            <Link to="/contact" className="btn my-2 btn-success btn-success-pulse" > Send Us A Message </Link>
                                        </div>

                                    </div>
                                </Col>

                                <Col className="d-none d-lg-block" lg="6" style={{textAlign: 'center'}}>

                                    <img alt="..." className="img-fluid" src={require("assets/img/undraw/svg21.svg")} />
                               
                                </Col>
                            </Row>
                        </div>
                    </Container>

                </div>

                <section className="section section-lg py-6 pb-8 position-relative bg-white">

                    <Container>

                        <Row>
                            <Col lg={6}>
                                <h2 className="display-3">Why We Started</h2>
                                <p>Architeck was started for one reason, to redefine the way business systems are created and implemented. </p>
                                <p>In business there are the tech nerds (like us), the marketers and sales people, the project managers, and the company owners... each speaking a very different language.</p>

                                <p><b>We build the systems that unify everyone, making each job more profitable and easier to do.</b></p>

                            </Col>
                            <Col lg={6} style={{textAlign: 'right'}}>
                                <img className="w-100" src={require('assets/img/brand/Graphic6.jpg')} alt="" />
                            </Col>
                        </Row>

                        <Row className="my-6">

                        <Col lg={6} className="align-self-center">
                                <img className="w-100" src={require('assets/img/brand/Graphic3.png')} alt="" />
                            </Col>
                            <Col lg={6} className="align-self-center">
                                <h2 className="display-3">How We Do It?</h2>
                                <p>It's simple, because we are the same as the people we work with. </p>
                                <p>We started as non technical founders who moved their way into tech. We understand what it's like to run a business and have no idea about how your systems work and function.</p>

                                <p>We also understand how to translate what you want into code and then back out into something profitable.</p>

                                <p><b>The perfect harmony of technical implementation with human interaction. </b></p>

                            </Col>
                          
                        </Row>

                        <div className="separator separator-bottom separator-skew zindex-100">
                            <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0" >
                            <polygon className="fill-default" style={{fill: '#f8f9fe'}} points="2560 0 2560 100 0 100" />
                            </svg>
                        </div>

                    </Container>
                </section>

                <section className="section section-lg py-9 position-relative">

                    <Container>

                        <Row>
                            <Col md={9} className="align-self-center">

                               
                                <Row>
                                    <Col md={4} >
                                        <h2 className="display-6 mb-0">Planning</h2>
                                        
                                        <p className="mb-0">Code Structure</p>
                                        <p className="mb-0">Integrations</p>
                                        <p className="mb-0">Data Flow</p>

                                        <h2 className="display-6 mb-0 mt-4">Strategy</h2>
                                        <p className="mb-0">Marketing</p>
                                        <p className="mb-0">Budget Consolidation</p>
                                        <p className="mb-0">Value Propositions</p>
                                        <p className="mb-0">Design</p>

                                       
                                        <h2 className="display-6 mb-0 mt-4">Research</h2>
                                        <p className="mb-0">Software Integration</p>
                                        <p className="mb-0">Database Structure</p>
                                        <p className="mb-0">Business Infrastructure</p>
                                        <p className="mb-0">Analytics</p>

                                    </Col>

                                    <Col md={4} >
                                        <h2 className="display-6 mb-0">Development</h2>
                                        <p className="mb-0">Front End</p>
                                        <p className="mb-0">Back End</p>
                                        <p className="mb-0">API Connection</p>
                                        <p className="mb-0">Server Connection</p>
                                        <p className="mb-0">Analytics</p>

                                        <h2 className="display-6 mb-0 mt-4">Monetization</h2>
                                        <p className="mb-0">Stripe</p>
                                        <p className="mb-0">Payment Gateways</p>
                                        <p className="mb-0">E-Commerce</p>
                                        <p className="mb-0">Value Ladder</p>

                                    </Col>

                                    <Col md={4} >

                                        <h2 className="display-6 mb-0 ">Post Launch</h2>
                                        <p className="mb-0">Project Management</p>
                                        <p className="mb-0">Server Monitoring</p>
                                        <p className="mb-0">Iterations</p>
                                        <p className="mb-0">Disaster Management</p>
                                        <p className="mb-0">Strategy Consulting</p>

                                    </Col>

                                </Row>
                               
                            </Col>
                            <Col md={3} className="align-self-center">
                                <img src="https://s3.us-east-2.amazonaws.com/jmaher1245-media-library/1559342171499" className="w-100 mt-4" alt="" />
                            </Col>
                        </Row>

                    </Container>

                 
                </section>

                <section className="bg-white section section-lg  py-7 bg-white text-center">
                    <Container>
                        <h1 className="display-2">Looking For A Business Solution?</h1>
                        <h2 className="display-4 font-weight-light">Learn more about which development model <b className="text-underline">is right for you.</b></h2>
                        <Link to="/development" className="btn btn-success">Learn More</Link>
                    </Container>
                </section>

                <ContactUs />

                
            </div>

           

        </>
        );
    }
}


const mapStateToProps = state => {


    return {
       
      
    };
};

  
export default connect(mapStateToProps )(Home);  