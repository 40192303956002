import React, { Component } from 'react';
import { Table } from 'reactstrap'

class TableComparison extends Component {


    state = {

    };

    render() {


        return (

            <Table className="align-item-center" responsive>
                <thead>
                    <tr>
                        <th></th>
                        <th className="text-muted">Pre-Built</th>
                        <th className="text-muted">Custom</th>
                        <th >Hybrid</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><b>Cost</b></td>
                        <td><span className="font-weight-bold tet-success">Low</span></td>
                        <td><span className="font-weight-bold tet-danger">High</span></td>
                        <td><span className="font-weight-bold tet-warning">Medium</span></td>
                    </tr>

                    <tr>
                        <td><b>Timeline</b></td>
                        <td>Instant</td>
                        <td>Typically 6+ Months</td>
                        <td>Typically 1-2 Months</td>
                    </tr>

                    <tr>
                        <td><b>Full Control</b></td>
                        <td>&mdash;</td>
                        <td><i className="fas fa-check text-success font-weight-bold"></i></td>
                        <td><i className="fas fa-check text-success font-weight-bold"></i></td>
                    </tr>

                    <tr>
                        <td><b>Custom UI/UX Design</b></td>
                        <td>&mdash;</td>
                        <td><i className="fas fa-check text-success font-weight-bold"></i></td>
                        <td><i className="fas fa-check text-success font-weight-bold"></i></td>
                    </tr>

                    <tr>
                        <td><b>Custom Functionality</b></td>
                        <td>&mdash;</td>
                        <td><i className="fas fa-check text-success font-weight-bold"></i></td>
                        <td><i className="fas fa-check text-success font-weight-bold"></i></td>
                    </tr>

                    <tr>
                        <td><b>Scales With You</b></td>
                        <td>?</td>
                        <td><i className="fas fa-check text-success font-weight-bold"></i></td>
                        <td><i className="fas fa-check text-success font-weight-bold"></i></td>
                    </tr>
                    <tr>
                        <td><b>Integrates With Other Software</b></td>
                        <td>?</td>
                        <td><i className="fas fa-check text-success font-weight-bold"></i></td>
                        <td><i className="fas fa-check text-success font-weight-bold"></i></td>
                    </tr>

                   
                    <tr>
                        <td><b>Fast To Market</b></td>
                        <td><i className="fas fa-check text-success font-weight-bold"></i></td>
                        <td>&mdash;</td>
                        <td><i className="fas fa-check text-success font-weight-bold"></i></td>
                    </tr>

                    <tr>
                        <td><b>Builds On Existing Work</b></td>
                        <td>&mdash;</td>
                        <td>&mdash;</td>
                        <td><i className="fas fa-check text-success font-weight-bold"></i></td>
                    </tr>

                    <tr>
                        <td><b>Quick Pivots</b></td>
                        <td>&mdash;</td>
                        <td>&mdash;</td>
                        <td><i className="fas fa-check text-success font-weight-bold"></i></td>
                    </tr>


                </tbody>
            </Table>

        )

    }

}

export default TableComparison;