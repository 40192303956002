import Axios from 'axios';
import keys from '../keys'

export const architeckCall = async (settings) => {
    return new Promise(async(resolve) => {



        const method = settings.method;
        const url = settings.url;
        let data = settings.data;
        const headers = settings.headers;
       
        if(!data) {
            data = {}
        }

        try {
            const result = await Axios({
                method,
                url: keys.API_URL + url,
                headers: {
                    ...headers,
                    authorization: `Bearer ${keys.SYSTEM_API_KEY}`
                },
                withCredentials: true,
                data,
            })

            resolve(result.data)
        
        } catch(e) {
        
            resolve({success: false, message: 'architeckCall failed at url: ' + url + '. Error: ' + e.toString()})
        
        }

    })
}
