
import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import ContactUs from '../components/forms/ContactUs';

class Goldfingr extends React.Component {

    render() {
                
        return (
        <>

            <div className="main-content">

                <div className="header bg-secondary pt-5 pb-7" >
                    <Container className=" pt-2 pb-3">
                        <div className="header-body">
                            <Row className="align-items-center">

                                <Col lg="6">
                                    <div className="pr-5 ">

                                        <h1 className="display-2  font-weight-bold mb-0" style={{lineHeight: '50px'}}>Goldfingr</h1>
                                        <h2 className="display-4  font-weight-light"> International Concierge Services </h2>

                                        <p className=" mt-4">   Goldfingr is an international social club that provides their users with unmatched concierge service. Their systems boasts interconnected web, Android, and IOS apps.</p>

                                        <div className="mt-5">
                                            <Link to="/contact" className="btn my-2 btn-success" > Send Us A Message </Link>
                                        </div>
                                    </div>
                                </Col>

                                <Col className="d-none d-lg-block" lg="6" style={{textAlign: 'center'}}>
                                    <img alt="..." className="rounded shadow-lg img-fluid ml-auto mr-auto mb-3" src="https://res.cloudinary.com/architeck/image/upload/v1575380075/Projects/Goldfingr/Goldfingr_fubv8o.jpg" />

                                </Col>
                            </Row>
                        </div>
                    </Container>

                </div>

                <section className="py-6 bg-white">
                    <Container>
                        <Row>
                            <Col lg={6}>
                                <h3 className="display-2"> Connecting The <br />World </h3>

                                <p className="">Goldfingr came to us to redesign their existing systems infastructure. <br />We took on the challenge by first consolidating their system down into a single code base with a well structured web app.</p>
                                <p>This gave them a simple code base to work with and both a website and IOS / Android store applications.</p>

                                <hr />

                                <p>After their presence was established we began the process of transitioning databases, and migrating their application over to React Native allowing the iPhone and Android apps to have increased functionality.</p>

                                <hr />

                                <p className="">Today Goldfingr Holds 4 Separate Code Bases:</p>
                                <ul>
                                    <li>Standard Web Application</li>
                                    <li>Centralized Node JS Server</li>
                                    <li>React Native Android App</li>
                                    <li>React Native IOS App</li>
                                </ul>

                            </Col>
                            <Col lg={6}>

                                <img className="shadow-lg rounded mt-4 w-100" src="https://res.cloudinary.com/architeck/image/upload/v1576063539/Projects/Goldfingr/Goldfingr11_juyijo.jpg" alt="..." />
                              

                                <Row>
                                    <Col lg={6}>
                                        <img className="shadow-lg rounded mt-4 w-100" src="https://res.cloudinary.com/architeck/image/upload/v1576063539/Projects/Goldfingr/Goldfingr12_uolypv.jpg" alt="..." />
                                    </Col>
                                    <Col lg={6}>
                                        <img className="shadow-lg rounded mt-4 w-100" src="https://res.cloudinary.com/architeck/image/upload/v1576063540/Projects/Goldfingr/Goldfingr13_bugxkz.jpg" alt="..." />
                                    </Col>
                                </Row>

                                <img className="shadow-lg rounded mt-4 w-100" src="https://res.cloudinary.com/architeck/image/upload/v1576063539/Projects/Goldfingr/Goldfingr10_j8ukax.jpg" alt="..." />
                    
                            </Col>
                           
                        </Row>
                    </Container>
                </section>

                <ContactUs />

            </div>

        </>
        );
    }
}
  
export default Goldfingr;  