import React, { Component } from 'react';
import { Badge, Col, Container, Row } from 'reactstrap';
import ContactMain from './ContactMain';


class ContactUs extends Component {


    state = {
        email: null,
        message: null,
        given_name: null,
        family_name: null
    };

   

    render() {


        return (

        <section className="bg-defaul  py-7  section-nucleo-icons ">
            <Container>

                <Row>
                    <Col md={6}>
                        <div className=" mb-6">
                            <h1 className="display-1 ">Send Us A Message</h1>
                            <p>It's not what you know, it's who you know... which is great because if you're reading this then you know us. </p>
                            <p>Now get to know how we work.</p>

                            <hr />

                            <div className="mb-4">
                                <Badge  className="p-2 px-4 bg-gradient-warning text-white">Free Advice</Badge>
                                <p>
                                    Meet us in person or schedule a call over the computer. If we meet up lunch is on us, and if not Google Hangouts works too. These sessions usually end in lots of free advice given out and you almost won't believe what just happened was free. If this should this prompt you to work with us we move on to step 2.                                    
                                </p>
                            </div>

                            <div className="mb-4">
                                <Badge  className="p-2 px-4 bg-gradient-info text-white">Game Plan</Badge>
                                <p>This is where we go to the drawing board and create an all encompassing game plan to get you from where you are now to where you want to be. Pricing, timeline, and functionality will all be laid out so you can see exactly what is going to happen.</p>
                            </div>


                            <div className="mb-4">
                                <Badge  className="p-2 px-4 bg-gradient-success text-white">Execution</Badge>
                                <p>This is the fun part, you get to watch things come alive as we do the hard work of executing on the game plan we created in step 2. We schedule regular stand up calls and and allow you to be as involved or stand offish as you want to be with your very own Architeck project management system.</p>
                            </div>


                            

                        </div>
                       
                    </Col>

                    <Col md={6} className="align-self-center">
                      <ContactMain />
                    </Col>

                    
                </Row>

            </Container>
        </section>


        )

    }

}

export default ContactUs;