import React, { Component } from 'react';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, FormGroup, Input, Row } from 'reactstrap';
import ReactBSAlert from "react-bootstrap-sweetalert";
import { architeckCall } from 'database';

const required_form_fields = [
    'given_name',
    'family_name',
    'email',
    'message',
]

class ContactUs extends Component {

    state = {
        email: null,
        message: null,
        given_name: null,
        family_name: null,
        canSave: true
    };

    fireAlert = (error) => {

        this.setState({
          alert: (
            <ReactBSAlert
              success={error ? false : true}
              danger={!error ? false : true}
              style={{ display: "block"}}
              title={error ? 'Error' : 'Success'}
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              confirmBtnBsStyle="success"
              confirmBtnText="Ok"
              btnSize=""
            >
                {error ? 
                    'Something went wrong sending your message, please try again.' :
                    'Message sent! We will get back to you within 48 hours.'
                }
            </ReactBSAlert>
          )
        });
    };

    hideAlert = () => this.setState({ alert: null });

    onSend = async () => {

        this.setState({canSave: false})
        //copy state
        let newState = Object.assign({}, this.state);
        //assume we have no errors
        let errors = 0;

        required_form_fields.forEach((field) => {

            //check each required field
            if(newState[field]) {
                newState[field + "State"] = 'valid'; 
            } else {
                newState[field + "State"] = 'invalid'; 
                errors++
            }

        })

        this.setState(newState);

          //et the user try again but stop form saving
        if(errors) {
            this.setState({canSave: true})
        } else {

            const name = this.state.given_name + ' ' + this.state.family_name;

            const sender_message = {
                to: this.state.email,
                from: 'john@architeck.io',
                subject: 'Artchiteck Contact Submission',
                title: 'Thank you for contacting Architeck.',
                body: 'We do our best to respond to all emails within 48 hours and will get back to you as soon as we can. ',
    
            }

            const admin_message = {
                to: 'john@architeck.io',
                from: 'webmaster@architeck.io',
                subject: 'Artchiteck Contact Submission',
                title: `${name} send you a support request.`,
                body: `New Contact Submission.<br /><br /> Name: ${name}<br /> Email: ${this.state.email}<br /><br /> Message: ${this.state.message}`,
    
            }
    
            const sendAdminMessage = await architeckCall({
                method: 'post',
                url: '/api/v1/integrations/sengrid/branded',
                data: admin_message,
            });

            console.log(sendAdminMessage)

            if(sendAdminMessage.success) {

                const sendSenderMessage = await architeckCall({
                    method: 'post',
                    url: '/api/v1/integrations/sengrid/branded',
                    data: sender_message,
                });

                console.log(sendSenderMessage)


                if(sendSenderMessage.success) {
                    this.fireAlert(false)
                    return;
                }

            }

            this.fireAlert(true)
            this.setState({canSave: true})

        }

    }

    onInputChange = (e, stateName) => {

        let newState = Object.assign({}, this.state);
        newState[stateName] = e.target.value;

        if (stateName === "checkbox") {
          if (e.target.value) {
            newState.checkboxState = "valid";
          } else {
            newState.checkboxState = "invalid";
          }
        } else {
          if (e.target.value === "") {
            newState[stateName + "State"] = "invalid";
          } else {
            newState[stateName + "State"] = "valid";
          }
        }

        this.setState(newState);
    };


    render() {


        return (

            <>
            {this.state.alert}
            <Card className="card-pricing border-0 text-center mb-4">

            <CardHeader className="bg-transparent">
                <h4 className="text-uppercase ls-1 text-info py-3 mb-0">
                Let's do this, Talk to us below.
                </h4>
            </CardHeader>

            <CardBody className="px-lg- text-left">

                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <label className="form-control-label" > First Name </label>

                            <Input
                                value={this.state.given_name || ''}
                                placeholder="John"
                                type="text"
                                valid={ this.state.given_nameState === "valid" }
                                invalid={ this.state.given_nameState === "invalid" }
                                onChange={e => this.onInputChange(e, "given_name") }
                                
                            />
                            <div className="valid-feedback">Looks good!</div>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <label className="form-control-label" > Last Name </label>

                            <Input
                                value={this.state.family_name || ''}
                                placeholder="Doe"
                                type="text"
                                valid={ this.state.family_nameState === "valid" }
                                invalid={ this.state.family_nameState === "invalid" }
                                onChange={e => this.onInputChange(e, "family_name") }
                                
                            />
                            <div className="valid-feedback">Looks good!</div>
                        </FormGroup>
                    </Col>
                </Row>

                <FormGroup>
                    <label className="form-control-label" > Email </label>

                    <Input
                        value={this.state.email || ''}
                        placeholder="johndoe@gmail.com"
                        type="text"
                        valid={ this.state.emailState === "valid" }
                        invalid={ this.state.emailState === "invalid" }
                        onChange={e => this.onInputChange(e, "email") }
                        
                    />
                    <div className="valid-feedback">Looks good!</div>
                </FormGroup>

                <FormGroup>
                    <label className="form-control-label" > Message </label>

                    <Input
                        value={this.state.message || ''}
                        placeholder="..."
                        type="textarea"
                        valid={ this.state.messageState === "valid" }
                        invalid={ this.state.messageState === "invalid" }
                        onChange={e => this.onInputChange(e, "message") }
                        
                    />
                    <div className="valid-feedback">Looks good!</div>
                </FormGroup>

            </CardBody>

            <CardFooter>
                <Button disabled={this.state.canSave ? false : true} onClick={this.onSend} className="btn btn-success btn-success-pulse" > Send Message </Button>
            </CardFooter>

        </Card>
        </>
        )

    }

}

export default ContactUs;