
import React from "react";
import { connect } from 'react-redux';
import { Col, Container, Row } from "reactstrap";
import ContactMain from './components/forms/ContactMain';
import Description from "./components/text/Description";


class Home extends React.Component {

    state = {

    }

    render() {
        
        return (
        <>

            <div className="main-content">

                <div className="header bg-secondary pt-3 pb-7">
                    <Container className=" pt-5 pb-7">
                        <div className="header-body">
                            <Row className="align-items-center">

                                <Col lg="6">
                                    <div className="pr-5">

                                        <h1 className="display-2  font-weight-bold mb-0" style={{lineHeight: '50px'}}>Want Free Advice? </h1>
                                        <h2 className="display-4  font-weight-light"> Yes Really, 100% Free </h2>

                                        <p className=" mt-4">We get excited about what we do and it absolutely shows. Most questions we can answer on the spot with responses and solutions that others pay big bucks for.</p>
                                        <p>Start with us and you'll most likely end with us. Even if you don't you'll leave better off than when you started and hey, the call is free.</p>


                                  
                                    </div>
                                </Col>

                                <Col lg="6" style={{textAlign: 'center'}}>

                                    <ContactMain />
                             
                                </Col>
                            </Row>
                        </div>
                    </Container>

                    <div className="separator separator-bottom separator-skew zindex-100">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                        <polygon
                            className="fill-default"
                            points="2560 0 2560 100 0 100"
                        />
                        </svg>
                    </div>
                </div>

                <Description />


            </div>
        </>
        );
    }
}


const mapStateToProps = state => {


    return {
       
      
    };
};

  
export default connect(mapStateToProps )(Home);  