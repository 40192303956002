
import React from "react";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Card, CardBody, CardImg, CardText, CardTitle, Col, Container, Row } from "reactstrap";
import ContactUs from './components/forms/ContactUs';


class Projects extends React.Component {

    state = {
      
    }

    render() {
                
        return (
        <>

            <div className="main-content">

                <div className="header bg-secondary pt-5 pb-7">
                    <Container className=" pt-2 pb-3">
                        <div className="header-body">
                            <Row className="align-items-center">

                                <Col lg="6">
                                    <div className="pr-5 ">

                                        <h1 className="display-2  font-weight-bold mb-0" style={{lineHeight: '50px'}}>Featured Projects</h1>
                                        <h2 className="display-4  font-weight-light"> Hybrid Development In  <b className="text-underline">Action</b> </h2>

                                        <p className=" mt-4">Below you will find a short list of the projects we have worked on, what we did, and what the outcome was. </p>

                                        <div className="mt-5">
                                            <Link to="/contact" className="btn my-2 btn-success btn-success-pulse" > Send Us A Message </Link>
                                        </div>
                                    </div>
                                </Col>

                                <Col className="d-none d-lg-block" lg="6" style={{textAlign: 'center'}}>
                                    <img alt="..." className="img-fluid ml-auto mr-auto mb-3" src="https://res.cloudinary.com/architeck/image/upload/v1575379566/Projects/3lt/3lt-1_jwzrya.png" />

                                   
                             
                                </Col>
                            </Row>
                        </div>
                    </Container>

                 

                </div>
                

                <section className="py-6 bg-white">

                    <Container>
                        <Row>
                            <Col lg={4}>
                                <Link to="/projects/3ltplaybook">
                                    <Card className="mb-4">
                                        <CardImg alt="..." src="https://res.cloudinary.com/architeck/image/upload/v1575380150/Projects/3lt/3ltplaybook_r1udpc.jpg" top />
                                        <CardBody>
                                            <CardTitle  className="text-dark">3LT Playbook</CardTitle>
                                            <CardText  className="text-muted">The 3LT Playbook encompasses everything a trader needs to know and understand about the Three Laws Of Trading created by Sang Lucci.</CardText>
                                            <CardText>
                                                <small className="text-muted">$100,000+ In Revenue On It's First Week Of Launch</small>
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </Link>
                            </Col>

                            <Col lg={4}>
                                <Link to="/projects/skytech">
                                    <Card className="mb-4">
                                        <CardImg style={{boxShadow: '0 0 2rem 0 rgba(136, 152, 170, 0.35)'}} alt="..." src="https://res.cloudinary.com/architeck/image/upload/v1582241300/Projects/SkyTech/Screen_Shot_2020-02-20_at_6.21.15_PM_wo0jik.png" top />
                                        <CardBody>
                                            <CardTitle className="text-dark">Sky Tech Holdings</CardTitle>
                                            <CardText  className="text-muted"> 
                                                Sky Tech is the creation of Ted X speaker Michael Gustin who has brought together a team of tech specialists and ex Nasa engineers focusing on a better future.
                                            </CardText>
                                            <CardText  className="text-muted">
                                                <small className="text-muted">Aerospace Engineering With A Future In Asteroid Mining</small>
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </Link>
                            </Col>

                            <Col lg={4}>
                                <Link to="/projects/goldfingr">
                                    <Card className="mb-4">
                                        <CardImg alt="..." src="https://res.cloudinary.com/architeck/image/upload/v1575380075/Projects/Goldfingr/Goldfingr_fubv8o.jpg" top />
                                        <CardBody>
                                            <CardTitle className="text-dark">Goldfingr</CardTitle>
                                            <CardText className="text-muted"> 
                                                Goldfingr is an international social club that provides their users with unmatched concierge service. Their systems boasts interconnected web, Android, and IOS apps.
                                            </CardText>
                                            <CardText>
                                                <small className="text-muted">Multiple Investments Secured</small>
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </Link>
                            </Col>
                            <Col lg={4}>
                                <Link to="/projects/exron">
                                    <Card className="mb-4">
                                        <CardImg style={{boxShadow: '0 0 2rem 0 rgba(136, 152, 170, 0.35)'}} alt="..." src="https://res.cloudinary.com/architeck/image/upload/v1575381210/Projects/Exron/ExronMusic_j4bg4d.jpg" top />
                                        <CardBody>
                                            <CardTitle  className="text-dark">Exron Music</CardTitle>
                                            <CardText className="text-muted"> 
                                                Exron is an independent Music Blog run by Kylie Parham and Charlotte Vosbeck. It shows off the brands unique design style with an easy to use user dashboard.
                                            </CardText>
                                            <CardText>
                                                <small className="text-muted">Over 500 Uniquely Crafted Articles</small>
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </Link>
                            </Col>
                         
                            <Col lg={4}>
                                <Link to="/projects/igre">
                                    <Card className="mb-4">
                                        <CardImg style={{boxShadow: '0 0 2rem 0 rgba(136, 152, 170, 0.35)'}} alt="..." src="https://res.cloudinary.com/architeck/image/upload/v1582238867/Projects/IgreCoaching/Igre6_ke97xk_ugb3x1.jpg" top />
                                        <CardBody>
                                            <CardTitle className="text-dark">IGRE Coaching</CardTitle>
                                            <CardText className="text-muted"> 
                                                IGRE Coaching is the brain child of Ed Stulak. It has been filmed and written over the span of 2 years and Launched on November 1st 2019.
                                            </CardText>
                                            <CardText>
                                                <small className="text-muted">Over 4 Hours Of Content And 58 Lessons</small>
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </Link>
                            </Col>
                            <Col lg={4}>
                                <Link to="/projects/withpurposerealty">
                                    <Card className="mb-4">
                                        <CardImg style={{boxShadow: '0 0 2rem 0 rgba(136, 152, 170, 0.35)'}} alt="..." src="https://res.cloudinary.com/architeck/image/upload/v1582238459/Projects/WithPurposeRealty/WithPurposeMain_xrlnfr.jpg" top />
                                        <CardBody>
                                            <CardTitle className="text-dark">With Purpose Realty</CardTitle>
                                            <CardText  className="text-muted"> 
                                                With Purpose Realty is a streamlined application making it easy for prospective home owners apply to buy a home and talk with respected Realtors.
                                            </CardText>
                                            <CardText  className="text-muted">
                                                <small className="text-muted">Integrated With Existing Company Infrastructure</small>
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </Link>
                            </Col>
                           

                            {/* 
                            <Col lg={4}>
                                <Card className="mb-4">
                                    <CardImg style={{boxShadow: '0 0 2rem 0 rgba(136, 152, 170, 0.35)'}} alt="..." src="https://res.cloudinary.com/architeck/image/upload/v1575380563/Projects/ManyPages/ManyPages_aktwuh.jpg" top />
                                    <CardBody>
                                        <CardTitle>Many Pages</CardTitle>
                                        <CardText> 
                                            This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                                        </CardText>
                                        <CardText>
                                            <small className="text-muted">$100,000+ in revenue on it's first week of launch</small>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg={4}>
                                <Card className="mb-4">
                                    <CardImg style={{boxShadow: '0 0 2rem 0 rgba(136, 152, 170, 0.35)'}} alt="..." src="https://res.cloudinary.com/architeck/image/upload/v1575381652/Projects/InstantApps/InstantApps_jmwni6.jpg" top />
                                    <CardBody>
                                        <CardTitle>Instant Apps</CardTitle>
                                        <CardText> 
                                            This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                                        </CardText>
                                        <CardText>
                                            <small className="text-muted">$100,000+ in revenue on it's first week of launch</small>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col lg={4}>
                                <Card className="mb-4">
                                    <CardImg style={{boxShadow: '0 0 2rem 0 rgba(136, 152, 170, 0.35)'}} alt="..." src="https://res.cloudinary.com/architeck/image/upload/v1575381530/Projects/EdStulak/EdStulak_vwq8rh.jpg" top />
                                    <CardBody>
                                        <CardTitle>Ed Stulak</CardTitle>
                                        <CardText> 
                                            This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                                        </CardText>
                                        <CardText>
                                            <small className="text-muted">$100,000+ in revenue on it's first week of launch</small>
                                        </CardText>
                                    </CardBody>
                                </Card>
                            </Col> */}
                        </Row>
                    </Container>
                </section>

              
                <ContactUs />

            </div>

        </>
        );
    }
}


const mapStateToProps = state => {


    return {
       
      
    };
};

  
export default connect(mapStateToProps )(Projects);  