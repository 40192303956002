/*
Documentation

this file renders the footer for the site layout

*/

import keys from 'keys';
import React from "react";
import { Link } from 'react-router-dom';
import { Col, Container, Nav, NavItem, Row } from "reactstrap";

class SiteFooter extends React.Component {

    render() {

        return (

        <>

        <footer className="py-5 bg-white pb-7">
            <Container>

                <Row>
                    <Col xs={6}>
                    <img className="w-50 mb-3" src={keys.LOGO} alt="logo" />

                    </Col>
                </Row>
                <Row className="align-items-centerustify-content-xl-between">

                    <Col md={5} >
                        <p>There is nothing I have more passion for then helping businesses and entrepreneurs create the systems that change lives. Both for themselves, their families, and the people that depend on them. That's what drives me every day to deliver the best products and services out there, day in and day out.</p>

                        <p><b>John Maher - Founder of Architeck</b></p>
                    </Col>

                    <Col md={7}>

                        
                        <Row>
                            <Col cm={3}>
                                <h5>Company</h5>
                                <ul style={styles.ul}>
                                    <li style={styles.li}><Link to="/about" className="text-muted">About Architeck</Link></li>
                                    <li style={styles.li}><Link to="/terms" className="text-muted">Terms Of Service</Link></li>
                                    <li style={styles.li}><a target="_blank" rel="noopener noreferrer" href="https://articles.architeck.io" className="text-muted">Knowledge Base</a></li>
                                    <li style={styles.li}><a target="_blank" rel="noopener noreferrer" href="https://projects.architeck.io" className="text-muted">My Projects</a></li>
                                    <li style={styles.li}><a target="_blank" rel="noopener noreferrer" href="https://developers.architeck.io" className="text-muted">Developers</a></li>
                                </ul>
                            </Col>

                            <Col cm={3}>
                                <h5>Information</h5>
                                <ul style={styles.ul}>
                                    <li style={styles.li}><Link to="/solutions" className="text-muted">Ready Made Solutions</Link></li>
                                    <li style={styles.li}><Link to="/architecture" className="text-muted">Architecture</Link></li>
                                    <li style={styles.li}><Link to="/development" className="text-muted">Development</Link></li>
                                </ul>
                            </Col>

                            <Col cm={6}>
                                <h5>Contact</h5>
                                <ul style={styles.ul}>
                                    <li style={styles.li} className="text-muted">john@architeck.com</li>
                                    <li style={styles.li} className="text-muted">(973) 897-5091</li>
                                </ul>
                            </Col>

                        </Row>
                    </Col>
                </Row>

                <hr />

                <Row>
                <Col xl="6">
                        <div className="copyright text-center text-xl-left text-muted">
                        © {new Date().getFullYear()}{" "}
                        
                            {keys.COMPANY} &mdash; {' '}
                            <a href="https://architeck.io" target="_blank" rel="noopener noreferrer">
                                Software Created By Architeck
                            </a>

                        </div>
                    </Col>
                    <Col xl="6">
                        <Nav className="nav-footer justify-content-center justify-content-xl-end">
                        
                            <NavItem>
                                <Link to="/terms" className="text-muted nav-link" >Terms Of Service</Link>
                            </NavItem>

                            <NavItem>
                                <Link to="/contact" className="text-muted nav-link" >Contact Us</Link>
                            </NavItem>

                        </Nav>
                    </Col>
                </Row>

            </Container>
        </footer>

        <span style={styles.chatContainer}>
            <Link to="/contact">
                <span style={styles.chat}>
                    <i style={styles.chatIcon} className="fas fa-comments text-info"></i>
                </span>
            </Link>
        </span>
        
        </>
        );
    }
}

const styles = {
    li: {
        listStyle: 'none'
    },

    ul: {
        paddingLeft: 0
    },

    chatContainer: {
        position: 'fixed',
        bottom: 40,
        right: 20
    }, 

    chat: {
        borderRadius: '100%',
        background: "#eee",
        padding: '15px 20px',
        boxShadow: '0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2)',
        border: 'inset 4px #11cdef'
    },

    chatIcon: {
       
    }
}

export default SiteFooter;
