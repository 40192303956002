import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';


class Description extends Component {


    state = {

    };

    render() {


        return (

            <section className="py-6 pb-9 bg-default position-relative">
                <Container fluid>
                    <Row className="justify-content-center text-center">
                        <Col md="6">
                        <h2 className="display-3 text-white" style={{textTransform: 'uppercase'}}>
                            A complete solution to business automation and your online presence.
                        </h2>
                            <p className="lead text-white">

                                Artchiteck was built from the ground with the experience from both non-technical and technical founders combined. We understand you because we are you. Payroll, technology, and building a business all have one thing in common, they're not easy and it's a damn lonely road along the way. That's why we're here, to work with you to overcome the struggles of business we all go through.


                            </p>

                        </Col>
                    </Row>
                   
                </Container>

                <div className="separator separator-bottom separator-skew zindex-100">
                    <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0" >
                    <polygon className="fill-default" style={{fill: 'white'}} points="2560 0 2560 100 0 100" />
                    </svg>
                </div>
               
            </section>

        )

    }

}

export default Description;