
import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import ContactUs from '../components/forms/ContactUs';

class ExronMuxi extends React.Component {

    render() {
                
        return (
        <>

            <div className="main-content">

                <div className="header bg-secondary pt-5 pb-7" >
                    <Container className=" pt-2 pb-3">
                        <div className="header-body">
                            <Row className="align-items-center">

                                <Col lg="6">
                                    <div className="pr-5 ">

                                        <h1 className="display-2  font-weight-bold mb-0" style={{lineHeight: '50px'}}>IGRE Coaching</h1>
                                        <h2 className="display-4  font-weight-light"> In The Gram For Real Estate </h2>

                                        <p className=" mt-4">IGRE Coaching is the brain child of Ed Stulak. It has been filmed and written over the span of 2 years and Launched on November 1st 2019.</p>

                                        <div className="mt-5">
                                            <Link to="/contact" className="btn my-2 btn-success" > Send Us A Message </Link>
                                        </div>
                                    </div>
                                </Col>

                                <Col className="d-none d-lg-block" lg="6" style={{textAlign: 'center'}}>
                                    <img alt="..." className="rounded shadow-lg img-fluid ml-auto mr-auto mb-3" src="https://res.cloudinary.com/architeck/image/upload/v1576065899/Projects/IgreCoaching/Igre3_xmzhpg.jpg" />

                                </Col>
                            </Row>
                        </div>
                    </Container>

                </div>

                <section className="py-6 bg-white">
                    <Container>
                        <Row>
                            <Col lg={6}>
                                <h3 className="display-2">Real Time Course Platform</h3>

                                <p className="">IGRE Coaching went though many renditions over the course of two years finally settling on a platform that delivers incredible content to realtors in a simple and clean format.</p>
                                <p>It is entirely manageable by founder and owner Ed Stulak and includes real time notifications of user sign ups, payments, and support requests</p>

                                <hr />

                                <p>The project was built using Node JS for the backend, React JS for the frontend with Mongo DB as a database.</p>

                                <hr />

                                <p className="">IGRE Features:</p>

                                <Row>
                                    <Col lg={6}>
                                        <ul>
                                            <li>Real Time Payments</li>
                                            <li>Real Time Support</li>
                                            <li>Module CMS</li>
                                            <li>Lesson CMS</li>
                                            <li>Lesson Progress Tracker</li>
                                        </ul>
                                    </Col>
                                    <Col lg={6}>
                                        <ul>
                                            <li>Stripe Integration</li>
                                            <li>Active Campaign Integration</li>
                                            <li>Vimeo Integration</li>
                                            <li>Cloudinary Integration</li>
                                        </ul>
                                    </Col>
                                </Row>
                               

                            </Col>
                            <Col lg={6}>

                                <img className="shadow-lg rounded mt-4 w-100" src="https://res.cloudinary.com/architeck/image/upload/v1576066063/Projects/IgreCoaching/Igre6_ke97xk.jpg" alt="..." />
                              

                                <Row>
                                    <Col lg={6}>
                                        <img className="shadow-lg rounded mt-4 w-100" src="https://res.cloudinary.com/architeck/image/upload/v1576066132/Projects/IgreCoaching/Igre7_ojku7b.jpg" alt="..." />
                                    </Col>
                                    <Col lg={6}>
                                        <img className="shadow-lg rounded mt-4 w-100" src="https://res.cloudinary.com/architeck/image/upload/v1576065899/Projects/IgreCoaching/Igre1_xqa7qm.jpg" alt="..." />
                                    </Col>
                                </Row>

                                <img className="shadow-lg rounded mt-4 w-100" src="https://res.cloudinary.com/architeck/image/upload/v1576066199/Projects/IgreCoaching/Igre8_r2ndgz.jpg" alt="..." />
                    
                            </Col>
                           
                        </Row>
                    </Container>
                </section>

                <ContactUs />

            </div>

        </>
        );
    }
}
  
export default ExronMuxi;  